import React from 'react';
import { Link } from 'react-router-dom';
import './Register.css';

const Register = () => {
    return (
        <div className="App">
            <div className="App-Pages">
                <div className="Page-Background"></div>
                <div className="titleCont">
                    <div className="title-top-pages">
                        Register for SAA 2025 today!
                    </div>
                </div>
                <div className="ContType4 HomeWideCont">
                <div className="twocols-container twocols-container-inverse">
                <div className="ContType4 HomeCont">
                    <div className="registration-info">
                        <h2>Registration fees</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th> </th><th>Early bird fee</th><th>Normal fee</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Full/Emeritus member</td><td>325 EUR</td><td>375 EUR</td>
                                </tr>
                                <tr>
                                    <td>Early Career member</td><td>175 EUR</td><td>225 EUR</td>
                                </tr>
                                <tr>
                                    <td>Low Income Country member</td><td>150 EUR</td><td>200 EUR</td>
                                </tr>
                                <tr>
                                    <td>Non-member</td><td>450 EUR</td><td>500 EUR</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            <b>Conference dinner + m-Party</b> (party sponsored by m-Path) (2 drinks included): 65 EUR
                        </p>
                    </div>
                    <div className="registerButton register-page-button">
                        <Link to="https://icts.kuleuven.be/apps/onebutton/registrations/834040">
                            <button>Register Now</button>
                        </Link>
                    </div>
                </div>
            </div>
            </div>
            </div>
        </div>
    );
}

export default Register;
